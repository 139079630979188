import mixpanel from 'mixpanel-browser';
import { IGetMe } from '../api/insights/responseTypes';

export function track(event: string, ...args: any[]) {
  mixpanel.track(event, ...args);
}

export function init(token: string) {
  mixpanel.init(token);
}

export function registerUser(user?: IGetMe) {
  if (user) {
    mixpanel.register({
      distinct_id: user.userKey,
      userKey: user.userKey,
      accountKey: user.accountKey,
    });

    mixpanel.identify(user.userKey);
    mixpanel.people.set({
      $email: user.email,
    });
  }
}
