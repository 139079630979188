import React from 'react';
import { useTranslation } from 'react-i18next';
import robotUrl from '../assets/robot.png';
import styles from './Unauthorized.scss';

export default function Unauthorized() {
  const { t } = useTranslation();
  return (
    <div className={styles.unauthorized}>
      <img alt="Robot" src={robotUrl} width="163" height="221" />
      <h1>{t('unauthorized.title')}</h1>
    </div>
  );
}
