import { createReducer } from '../createReducer';
import * as actions from './actions';

export interface ISessionState {
  data: {
    authToken: string;
    product: string;
    referenceKey: string;
  };
}

const initialState: ISessionState = {
  data: {
    authToken: undefined,
    product: undefined,
    referenceKey: undefined,
  },
};

const handleSetHashParams = (state: ISessionState, action: ReturnType<typeof actions.setHashParams>) => ({
  ...state,
  data: {
    ...state.data,
    ...action.payload,
  },
});

export default createReducer<ISessionState, actions.SessionActions>(initialState, {
  [actions.setHashParams.toString()]: handleSetHashParams,
});
