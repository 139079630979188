import * as LDClient from 'launchdarkly-js-client-sdk';
import { IGetMe } from '../api/insights/responseTypes';
import config from '../appConfig';

class FeatureService {
  private flags = {};

  setFlags = (featureFlags: any) => {
    this.flags = featureFlags;
  };
  getFeatureFlag = (featureFlagKey) => this.flags[featureFlagKey];
  getFeaturedFlags = (userData: IGetMe) => {
    let Proresolve;
    let Proreject;
    const featureFlagPromise = new Promise((resolve, reject) => {
      Proresolve = resolve;
      Proreject = reject;
    });
    const user = {
      key: userData.userKey,
      email: userData.email,
      privateAttributeNames: ['email'],
      custom: {
        accountKey: userData.accountKey,
        g2m_locale: userData.locale || 'en_US',
      },
    };
    const ldclient = LDClient.initialize(config.launchDarkly.clientSideId, user);
    ldclient.on('ready', () => {
      try {
        const featuredFlags = ldclient.allFlags();
        this.flags = featuredFlags;
        Proresolve(featuredFlags);
      } catch (err) {
        Proreject(err);
      }
    });
    return featureFlagPromise;
  };
}

export default FeatureService;
