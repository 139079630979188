exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-Notices-InfoHeader-InfoHeader--infoHeader {\n  color: #25282d;\n  width: 100%;\n  height: auto;\n  border-radius: 3px;\n  display: flex;\n  padding: 15px;\n  position: relative;\n  margin-top: 15px;\n  font-size: 16px;\n  border: solid 1px #006aff;\n  background-color: rgba(64, 144, 255, 0.08); }\n  .src-components-Notices-InfoHeader-InfoHeader--infoHeader .src-components-Notices-InfoHeader-InfoHeader--topSection {\n    margin-bottom: 10px; }\n  .src-components-Notices-InfoHeader-InfoHeader--infoHeader .src-components-Notices-InfoHeader-InfoHeader--middleSection {\n    margin-top: 14px; }\n  .src-components-Notices-InfoHeader-InfoHeader--infoHeader .src-components-Notices-InfoHeader-InfoHeader--lastSection {\n    margin-top: 14px; }\n  .src-components-Notices-InfoHeader-InfoHeader--infoHeader .src-components-Notices-InfoHeader-InfoHeader--closeIcon {\n    cursor: pointer;\n    font-size: 18px;\n    right: 15px;\n    top: 25px;\n    position: absolute;\n    font-weight: bold; }\n  .src-components-Notices-InfoHeader-InfoHeader--infoHeader .src-components-Notices-InfoHeader-InfoHeader--icon {\n    color: #006aff;\n    font-size: 25px;\n    margin-right: 13px; }\n  .src-components-Notices-InfoHeader-InfoHeader--infoHeader ol {\n    padding-left: 20px; }\n", ""]);

// exports
exports.locals = {
	"infoHeader": "src-components-Notices-InfoHeader-InfoHeader--infoHeader",
	"topSection": "src-components-Notices-InfoHeader-InfoHeader--topSection",
	"middleSection": "src-components-Notices-InfoHeader-InfoHeader--middleSection",
	"lastSection": "src-components-Notices-InfoHeader-InfoHeader--lastSection",
	"closeIcon": "src-components-Notices-InfoHeader-InfoHeader--closeIcon",
	"icon": "src-components-Notices-InfoHeader-InfoHeader--icon"
};