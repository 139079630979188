import { createReducer } from '../createReducer';
import * as actions from './actions';

const initialState = {
  data: {
    title: '',
  },
};

export interface IMeetingState {
  data: {
    title: string;
  };
}

const handleMeetingTitle = (state: IMeetingState, action) => ({
  ...state,
  data: {
    ...state.data,
    ...action.payload,
  },
});
export default createReducer(initialState, {
  [actions.setMeetingTitle.toString()]: handleMeetingTitle,
});
