import React from 'react';
import styles from './MeetingTitle.scss';

interface IProps {
  meetingDetails: {
    data: {
      title: string;
    };
  };
}
const MeetingTitle: React.FC<IProps> = (props) => {
  if (props.meetingDetails.data.title) {
    return <h1 className={styles.title}>{props.meetingDetails.data.title}</h1>;
  }
};

export default MeetingTitle;
