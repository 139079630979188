exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-App-App--app {\n  align-items: center;\n  display: flex;\n  color: black;\n  flex-direction: column; }\n\n.src-components-App-App--notes {\n  height: 100%;\n  max-width: 700px;\n  width: 100%;\n  padding: 10px; }\n\n.src-components-App-App--notes1 {\n  padding: 10px;\n  font-family: ToGo; }\n\n.src-components-App-App--notes2 {\n  font-family: -apple-system, BlinkMacSystemFont, SegoeUI, Roboto, Helvetica, Arial, \"Apple Color Emoji\", \"SegoeUI Emoji\", \"Segoe UI Symbol\", sans-serif; }\n", ""]);

// exports
exports.locals = {
	"app": "src-components-App-App--app",
	"notes": "src-components-App-App--notes",
	"notes1": "src-components-App-App--notes1",
	"notes2": "src-components-App-App--notes2"
};