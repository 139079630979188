export const fetchJson = <T>(...args: Parameters<typeof fetch>) =>
  fetch(...args).then((res) => {
    if (!res.ok) {
      throw new Error(`${res.status} ${res.statusText}`);
    } else if (res.status === 204) {
      return undefined;
    }

    return res.json() as Promise<T>;
  });

export const fetchJsonWithAuth = <T>(url: string, token: string, options: any = {}) =>
  fetchJson<T>(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
