import { api as noteApis } from '@getgo/notes';
import config from '../../appConfig';
import { fetchJsonWithAuth } from '../../utils/fetch';
import { IGetMe } from '../insights/responseTypes';
import { IGetJwtToken, INote } from './responseTypes';

const baseUrl = config.notesService.url;

export const getNotes = (jwtToken: string, userKey: string, product: string, referenceKey: string): Promise<INote[]> =>
  noteApis.getNotes({
    origin: 'insession',
    baseUrl,
    jwtToken,
    userKey,
    product,
    referenceKey,
  });

export const getJwtToken = (token: string, userKey: IGetMe['userKey']) =>
  fetchJsonWithAuth<IGetJwtToken>(`${baseUrl}/v1/notes/users/${userKey}/tokens`, token);
