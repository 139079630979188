/**
 * Type-safe access of deep property of an object. *
 * @param obj                   Object to get deep property
 * @param unsafeDataOperation   Function that returns the deep property
 * @param valueIfFail           Value to return in case if property not found
 */
export const get = <O, T>(obj: O, unsafeDataOperation: (x: O) => T, valueIfFail?: any): T => {
  try {
    return unsafeDataOperation(obj);
  } catch (error) {
    return valueIfFail;
  }
};
