import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import promiseMiddleware from 'redux-promise-middleware';
import mixpanelMiddleware from '../middleware/mixpanel';
import reduxLoggerMiddleware from '../middleware/reduxLogger';
import reducers from '../redux/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [mixpanelMiddleware, promiseMiddleware, reduxLoggerMiddleware];

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

persistStore(store);

export default store;
