exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-Header-Header--header {\n  width: 100%;\n  height: 54px;\n  background-color: #f0f2f5;\n  display: flex;\n  align-items: center;\n  justify-content: center; }\n  .src-components-Header-Header--header img {\n    height: 24px;\n    width: 168px; }\n\n.src-components-Header-Header--closeIcon {\n  cursor: pointer;\n  font-size: 18px;\n  margin: 18px 10px 0 20px; }\n\n.src-components-Header-Header--icon {\n  font-size: 24px;\n  margin: 18px 20px 0 10px; }\n\n.src-components-Header-Header--text {\n  color: white;\n  flex: 1;\n  font-size: 18px; }\n  .src-components-Header-Header--text p {\n    margin-bottom: 10px; }\n", ""]);

// exports
exports.locals = {
	"header": "src-components-Header-Header--header",
	"closeIcon": "src-components-Header-Header--closeIcon",
	"icon": "src-components-Header-Header--icon",
	"text": "src-components-Header-Header--text"
};