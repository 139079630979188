import { ISessionState } from './reducer';

export const createHashMapFromHashStr = (hashStr: string): ISessionState['data'] => {
  const splitHash = hashStr.split(/[#&]/).filter((h) => h);
  const hashMap = splitHash.reduce((map: any, str) => {
    const [key, value] = str.split('=');
    map[key] = value;
    return map;
  }, {});
  return hashMap;
};
