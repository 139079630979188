import { mergeDeep } from './utils/merge';

const environment = process.env.ENVIRONMENT;

const config = {
  env: environment,
  name: 'g2m-notes',
  insightsService: {
    url: 'https://apied1.insights.g2m.test.expertcity.com',
  },
  mixpanel: {
    token: 'd2eca1ec6d277880d8852d3fe4d5b653',
  },
  notesService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com',
  },
  urls: {
    g2m: 'https://globaled1.g2m.test.expertcity.com',
  },
  meetings: {
    url: 'https://apiglobaled1.g2m.test.expertcity.com',
  },
  launchDarkly: {
    clientSideId: '5aaab91a171e7c2a95c3a6db',
  },
  logging: {
    source: 'logmein.g2m.smartNotesSPA',
    url: 'https://logginged1.getgo.test.expertcity.com',
  },
};

if (process.env.ENVIRONMENT === 'dev' || process.env.ENVIRONMENT === 'undefined') {
  mergeDeep(config, {
    env: 'development',
  });
}

if (process.env.ENVIRONMENT === 'ed') {
  mergeDeep(config, {
    insightsService: {
      url: 'https://apied1.insights.g2m.test.expertcity.com',
    },
    notesService: {
      url: 'https://apied-featureservices.devplatform-dev.expertcity.com',
    },
    urls: {
      g2m: 'https://globaled1.g2m.test.expertcity.com',
    },
    meetings: {
      url: 'https://apiglobaled1.g2m.test.expertcity.com'
    },
    logging: {
      url: 'https://logginged1.getgo.test.expertcity.com',
    },
    launchDarkly: {
      clientSideId: '5aaab91a171e7c2a95c3a6db',
    },
  });
}

if (process.env.ENVIRONMENT === 'rc') {
  mergeDeep(config, {
    insightsService: {
      url: 'https://apirc1.insights.g2m.test.expertcity.com',
    },
    notesService: {
      url: 'https://apirc-featureservices.devplatform-dev.expertcity.com',
    },
    urls: {
      g2m: 'https://globalrc1.g2m.test.expertcity.com',
    },
    meetings: {
      url: 'https://apiglobalrc1.g2m.test.expertcity.com'
    },
    logging: {
      url: 'https://loggingrc1.getgo.test.expertcity.com',
    },
    launchDarkly: {
      clientSideId: '5b16f33028156f319e66ba08',
    },
  });
}

if (process.env.ENVIRONMENT === 'stage') {
  mergeDeep(config, {
    insightsService: {
      url: 'https://apistage.insights.gotomeeting.com',
    },
    notesService: {
      url: 'https://apistage.services.gotomeeting.com',
    },
    urls: {
      g2m: 'https://globalstage.gotomeeting.com',
    },
    meetings: {
      url: 'https://apiglobalstage.gotomeeting.com'
    },
    logging: {
      url: 'https://loggingstage.getgo.com',
    },
    launchDarkly: {
      clientSideId: '5b16f33b88a2b83198680027',
    },
  });
}

if (process.env.ENVIRONMENT === 'live') {
  mergeDeep(config, {
    env: 'production',
    insightsService: {
      url: 'https://api.insights.gotomeeting.com',
    },
    notesService: {
      url: 'https://api.services.gotomeeting.com',
    },
    mixpanel: {
      token: 'c73c1059c67c323751ca7a4e3dd1fcc8',
    },
    urls: {
      g2m: 'https://global.gotomeeting.com',
    },
    meetings: {
      url: 'https://apiglobal.gotomeeting.com',
    },
    launchDarkly: {
      clientSideId: '5aaab91a171e7c2a95c3a6dc',
    },
    logging: {
      url: 'https://logging.getgo.com',
    },
  });
}

export default config;
