import { Spinner } from '@getgo/chameleon-react';
import { actions as noteActions } from '@getgo/notes';
import 'bootstrap/dist/css/bootstrap-reboot.css';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import * as insightsApi from './api/insights/insights';
import { getMeetingDetails } from './api/meetings/details';
import * as notesApi from './api/notes/notes';
import config from './appConfig';
import Unauthorized from './components/Error/Unauthorized/Unauthorized';
import { featureKeys } from './constants/globalConstants';
import App from './containers/App/AppContainer';
import { getJwtToken } from './redux/auth/actions';
import { setFeaturedFlags } from './redux/LaunchDarkly/actions';
import { setMeetingTitle } from './redux/meeting/actions';
import { setHashParams } from './redux/session/actions';
import { getMe } from './redux/user/actions';
import FeatureService from './services/featureService';
import { init as initLocalization } from './services/localization';
import loggerService from './services/loggerService';
import store from './store';
import { identify as appCuesIdentify, init as initAppCues } from './utils/appcues';
import * as mixpanel from './utils/mixpanel';
import { get } from './utils/object';
import { updateFavIcon } from './utils/rebranding';

initAppCues();

const renderApp = () => {
  render(
    <Suspense fallback={undefined}>
      <Provider store={store}>
        <App />
      </Provider>
    </Suspense>,
    document.getElementById('root')
  );
};

const renderLoading = () => {
  render(<Spinner id="base-spinner" size="large" />, document.getElementById('root'));
};

const renderUnauthorized = () => render(<Unauthorized />, document.getElementById('root'));

const redirectJwtTimeout = (tokenExpiry: number) =>
  setTimeout(() => {
    window.location.href = `${config.urls.g2m}/#meetings/past`;
  }, tokenExpiry - Date.now());

const featureService = new FeatureService();


export const init = async () => {
  renderLoading();
  await initLocalization();
  mixpanel.init(config.mixpanel.token);

  store.dispatch(setHashParams(window.location.hash));
  window.location.hash = '';

  const state = store.getState();
  const { authToken, product, referenceKey } = state.session.data;
  const jwtToken = get(state, (s) => s.auth.data.token);
  const tokenExpiry = get(state, (s) => s.auth.data.tokenExpiry);
  let userKey = get(state, (s) => s.user.data.userKey);
  let userData = get(state, (s) => s.user.data);
  const storedMeetingDetails = get(state, (s) => s.meeting.data);

  if (!authToken && !product && !referenceKey && !jwtToken && !userKey) {
    window.location.href = config.urls.g2m;
    return;
  }
  if (tokenExpiry) {
    redirectJwtTimeout(tokenExpiry);
  }

  try {
    if (jwtToken && userKey) {
      const notesRes = await notesApi.getNotes(jwtToken, userKey, product, referenceKey);
      store.dispatch(noteActions.getNotes.fulfilled(notesRes));
    } else {
      const userRes = await insightsApi.getMe(authToken);
      mixpanel.registerUser(userRes);
      store.dispatch(getMe.fulfilled(userRes));
      userKey = userRes.userKey;
      userData = userRes;
      const jwtRes = await notesApi.getJwtToken(authToken, userRes.userKey);
      redirectJwtTimeout(jwtRes.tokenExpiry);
      store.dispatch(getJwtToken.fulfilled(jwtRes));

      const notesRes = await notesApi.getNotes(jwtRes.token, userRes.userKey, product, referenceKey);
      store.dispatch(noteActions.getNotes.fulfilled(notesRes));
    }

    const flags = await featureService.getFeaturedFlags(userData);
    store.dispatch(setFeaturedFlags(flags));
    updateFavIcon(flags[featureKeys.ENABLEREBRANDING]);
    const hasTitleStored = storedMeetingDetails && storedMeetingDetails.title !== '';
    if (flags[featureKeys.NOTESFEATUREFLAG] && !hasTitleStored) {
      const meetingDetails = await getMeetingDetails(authToken, referenceKey);
      store.dispatch(setMeetingTitle(meetingDetails));
    }

    const user = get(store.getState(), (s) => s.user.data);
    appCuesIdentify(user);

    renderApp();
  } catch (e) {
    loggerService.error('notesAppNotLoading', e);
    renderUnauthorized();
  }
};

if (process.env.NODE_ENV !== 'test') {
  init();
}
