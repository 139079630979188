import { createReducer } from '../createReducer';
import * as actions from './actions';

const initialState = {
  data: {
    features: {},
  },
};

export interface ISessionState {
  data: {
    features: {
      [key: string]: string;
    };
  };
}

const setFeaturedFlags = (state: ISessionState, action) => ({
  ...state,
  data: {
    ...state.data.features,
    ...action.payload,
  },
});
export default createReducer(initialState, {
  [actions.setFeaturedFlags.toString()]: setFeaturedFlags,
});
