import Logger from '@getgo/logger';
import LoggingServiceLogTarget from '@getgo/logger-target-logging-service';
import platform from 'platform';
import { v4 } from 'uuid';
import config from '../appConfig';

const MACHINEID = 'machineId';

const machine = (() => {
  let id = localStorage.getItem(MACHINEID);

  if (!id) {
    const machineId = v4();
    localStorage.setItem(MACHINEID, machineId);
    id = machineId;
  }
  return { id };
})();
const createAppLogger = () => {
  const plugins = [];

  if (!['dev', 'test'].includes(process.env.ENVIRONMENT)) {
    plugins.push(
      new LoggingServiceLogTarget({
        server: config.logging.url,
        context: {
          ls_source: config.logging.source,
          product: config.name,
          env: config.env,
        },
      })
    );
  }

  const logger = Logger.createRoot(plugins);

  logger.setContext('loggingId', Date.now());
  logger.setContext('machineId', machine.id);
  logger.setContext('browser', {
    name: platform.name,
    version: platform.version,
    'os.family': platform.os.family,
    'os.version': platform.os.version,
    language: navigator.language,
  });
  logger.logToConsole = false;

  return logger;
};

export default createAppLogger();
