import { IGetMe } from '../api/insights/responseTypes';
import { track as mixpanelTrack } from './mixpanel';

const appcuesName = 'Appcues';
const supportedEvents = [
  'flow_started',
  'flow_completed',
  'flow_skipped',
  'step_started',
  'step_completed',
  'step_skipped',
  'step_interacted',
  'form_submitted',
  'form_field_submitted',
];

export const init = () => {
  try {
    window.Appcues.page();
    window.Appcues.on('all', (name, { flowName, flowType, name: eventName }) => {
      if (supportedEvents.includes(name)) {
        mixpanelTrack(appcuesName, { name, flowName, flowType, eventName });
      }
    });
  } catch (e) {
    // fail silently
  }
};

export const identify = (user: IGetMe, properties: { [property: string]: boolean | string | number } = {}) => {
  try {
    if (user) {
      window.Appcues.identify(user.userKey, {
        accountKey: user.accountKey,
        userKey: user.userKey,
        ...properties,
      });
    }
  } catch (e) {
    // fail silently
  }
};
