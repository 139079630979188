exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-MeetingDetails-MeetingTitle--title {\n  font-size: 36px;\n  font-weight: bold;\n  color: #25282d;\n  line-height: 1.4;\n  margin-bottom: 4px;\n  font-family: -apple-system, BlinkMacSystemFont, SegoeUI, Roboto, Helvetica, Arial, \"Apple Color Emoji\", \"SegoeUI Emoji\", \"Segoe UI Symbol\", sans-serif;\n  margin-left: 10px; }\n", ""]);

// exports
exports.locals = {
	"title": "src-components-MeetingDetails-MeetingTitle--title"
};