import Notes from '@getgo/notes';
import { InSessionEditorWrapper } from '@getgo/notes2';
import classnames from 'classnames';
import React from 'react';
import config from '../../appConfig';
import { featureKeys } from '../../constants/globalConstants';
import { RootReducer } from '../../redux/rootReducer';
import loggerService from '../../services/loggerService';
import { track } from '../../utils/mixpanel';
import Header from '../Header/Header';
import MeetingTitle from '../MeetingDetails/MeetingTitle';
import InfoHeader from '../Notices/InfoHeader/InfoHeader';
import styles from './App.scss';

export interface IAppProps {
  auth: RootReducer['auth'];
  session: RootReducer['session'];
  user: RootReducer['user'];
  meeting: RootReducer['meeting'];
  notes: RootReducer['notes'];
  featureFlags: {
    [key: string]: boolean;
  };
}

const origin = 'insession';

class App extends React.Component<IAppProps> {
  evaluateHost = () => {
    try {
      const referrer = document.referrer;
      if (referrer && referrer.length > 0) {
        return referrer.indexOf('jive.com') > -1 || referrer.indexOf('/domain/') > -1;
      }
      return false;
    } catch {
      return false;
    }
  };

  componentDidMount() {
    window.addEventListener(
      'beforeunload',
      (event) => {
        event.preventDefault();
        return (event.returnValue = '');
      },
      { capture: true }
    );
  }
  render() {
    const { auth, session, user, meeting, featureFlags } = this.props;
    const isNewNotes = featureFlags[featureKeys.NOTESFEATUREFLAG];
    const isRebrandingEnabled = featureFlags[featureKeys.ENABLEREBRANDING] || false;
    const notJiveApp = !this.evaluateHost();
    const mixPanelProductName = notJiveApp ? session.data.product : 'g2c';
    const notesView = isNewNotes ? (
      <>
        {notJiveApp && <MeetingTitle meetingDetails={meeting} />}
        <InSessionEditorWrapper
          baseUrl={config.notesService.url}
          jwtToken={auth.data.token}
          product={session.data.product}
          referenceKey={session.data.referenceKey}
          userKey={user.data.userKey}
          origin={origin}
          authToken={session.data.authToken}
          notes={this.props.notes.data}
          mixPanelTrack={track}
          logger={loggerService}
          mixPanelProductName={mixPanelProductName}
          locale={user.data.locale}
        />
      </>
    ) : (
      <Notes
        baseUrl={config.notesService.url}
        jwtToken={auth.data.token}
        product={session.data.product}
        referenceKey={session.data.referenceKey}
        userKey={user.data.userKey}
        origin={origin}
      />
    );
    const fontStyles = isNewNotes ? styles.note2 : styles.note1;
    return (
      <div className={classnames(styles.app, fontStyles)}>
        {notJiveApp && <Header isRebrandingEnabled={isRebrandingEnabled} />}
        <div className={styles.notes}>
          {!isNewNotes && <InfoHeader />}
          {notesView}
        </div>
      </div>
    );
  }
}

export default App;
