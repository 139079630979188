import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as localStorage from '../../../utils/localStorage';
import styles from './InfoHeader.scss';

const hideInfoHeaderMessageStorage = 'hideInfoHeaderMessage';
const InfoHeader = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const toggleActiveCb = useCallback(() => {
    localStorage.setItem(hideInfoHeaderMessageStorage, 'true');
    setActive(!active);
  }, [active]);

  useEffect(() => {
    const showInfoHeaderMessage = localStorage.getItem(hideInfoHeaderMessageStorage) !== 'true';
    setActive(showInfoHeaderMessage);
  }, []);

  return (
    active && (
      <div className={styles.infoHeader}>
        <div className={styles.icon}>
          <i className="togo-icon togo-icon-info" />
        </div>
        <div>
          <div className={styles.topSection}>{t('infoHeader.topSection')}</div>
          <ol>
            <li>{t('infoHeader.firstListOption')}</li>
            <li>{t('infoHeader.secondListOption')}</li>
            <li>{t('infoHeader.ThirdListOption')}</li>
          </ol>
          <div className={styles.middleSection}>{t('infoHeader.middleSection')}</div>
          <div className={styles.lastSection}>{t('infoHeader.lastSection')}</div>
        </div>
        <i className={classnames('togo-icon togo-icon-closes', styles.closeIcon)} onClick={toggleActiveCb} />
      </div>
    )
  );
};

export default InfoHeader;
