import { connect } from 'react-redux';
import App from '../../components/App/App';
import { RootReducer } from '../../redux/rootReducer';

const mapStateToProps = (state: Partial<RootReducer>) => ({
  auth: state.auth,
  session: state.session,
  user: state.user,
  meeting: state.meeting,
  featureFlags: state.features.data,
  notes: state.notes,
});

export default connect<ReturnType<typeof mapStateToProps>>(mapStateToProps)(App);
