exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-Error-Unauthorized-Unauthorized--unauthorized {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  padding-top: 25px; }\n", ""]);

// exports
exports.locals = {
	"unauthorized": "src-components-Error-Unauthorized-Unauthorized--unauthorized"
};