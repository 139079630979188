import { actions as noteActions } from '@getgo/notes';
import { Middleware } from 'redux';
import { RootReducer } from '../redux/rootReducer';
import { track } from '../utils/mixpanel';

const findNoteById = (state: RootReducer, id: string) => state.notes.data.find((note) => note.id === id);
const isNoteBookmark = (note: ReturnType<typeof findNoteById>) => !note.text.replace(/\s/g, '').length;
const origin = 'insession';

const mixpanelMiddleware: Middleware<{}, RootReducer> = (store) => (next) => (action) => {
  const state = store.getState();

  switch (action.type) {
    case noteActions.createNote.fulfilled.toString():
      track('Smart Note Created', {
        isBookmark: isNoteBookmark(action.payload),
        type: action.payload.type,
        origin,
      });
      break;
    case noteActions.editNote.fulfilled.toString():
      const editedNote = findNoteById(state, action.payload.id);
      if (editedNote) {
        track('Smart Note Edited', {
          isBookmark: isNoteBookmark(editedNote),
          type: action.payload.type,
          typeChanged: action.payload.type !== editedNote.type,
          textChanged: action.payload.text !== editedNote.text,
          origin,
        });
      }
      break;
    case noteActions.deleteNote.fulfilled.toString():
      const id = action.payload;
      const deletedNote = findNoteById(state, id);
      if (deletedNote) {
        track('Smart Note Deleted', {
          isBookmark: isNoteBookmark(deletedNote),
          origin,
        });
      }
      break;
    default:
      break;
  }
  return next(action);
};

export default mixpanelMiddleware;
