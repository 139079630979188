export const getItem = (key: string): string => {
  try {
    return localStorage.getItem(key);
  } catch {
    return undefined;
  }
};

export const setItem = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // fail silently to avoid page errors
  }
};
