import React from 'react';
import g2mLogo from './assets/g2m-logo.svg';
import gotoLogo from '../../public/g2m-black-yellow.svg'
import styles from './Header.scss';
interface IHeaderProps {
    isRebrandingEnabled: boolean
}

const Header = (props :IHeaderProps) => (
        <div className={styles.header}>
             <img src= {props.isRebrandingEnabled ? gotoLogo : g2mLogo} />
        </div>
);

export default Header;
