import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const getShortLocale = (locale: string = 'en') => locale.substr(0, 2).toLowerCase();

export const init = () =>
  i18next
    .use(XHR)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `locales-${__COMMIT_HASH__}/en/{{ns}}.json`, // TODO: inject dynamic locales once we support them (en -> {{lng}})
      },
      debug: false,
      defaultNS: 'translations',
      fallbackLng: 'en',
      lng: getShortLocale(navigator.language),
      ns: ['translations'],
      whitelist: ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'zh'],
    });
