import { Action } from 'redux';

interface IHandlers<State, Actions extends Action> {
  readonly [type: string]: (state: State, action: Actions) => State;
}

export const createReducer = <State, Actions extends Action>(initialState: State, handlers: IHandlers<State, Actions>) => (
  state = initialState,
  action: Actions
) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};
