import { reducer as notes } from '@getgo/notes';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import auth from './auth/reducer';
import features from './LaunchDarkly/reducer';
import meeting from './meeting/reducer';
import session from './session/reducer';
import user from './user/reducer';

const userPersistConfig = {
  key: 'user',
  storage,
};

const authPersistConfig = {
  key: 'auth',
  storage,
};

const sessionPersistConfig = {
  key: 'session',
  storage,
};

const featuredFlag = {
  key: 'features',
  storage,
};

const meetingPersistConfig = {
  key: 'meeting',
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  user: persistReducer(userPersistConfig, user),
  notes,
  session: persistReducer(sessionPersistConfig, session),
  meeting: persistReducer(meetingPersistConfig, meeting),
  features: persistReducer(featuredFlag, features),
});

export type RootReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
